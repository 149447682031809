<template>
  <div>
    <h1>O365 Migration Pricing Calculator</h1>
    <div class="row">
      <div class="col-md-8">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Time to do</th>
                <th>Total Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ item.name }}</td>
                <td>
                  <b-form-input
                    type="number"
                    v-model="item.quantity"
                    @input="updateCosts"
                  ></b-form-input>
                </td>
                <td>{{ item.timeToDo }}</td>
                <td>{{ item.totalTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-4">
        <p><b>Total hours:</b> {{ totalHours }}</p>
        <p><b>Total days:</b> {{ totalDays }}</p>
        <p><b>Total cost:</b> £{{ totalCost }}</p>
        <div>
          <button class="btn btn-primary" v-b-modal="'proposalModal'">
            Create Proposal
          </button>
          <b-modal
            id="proposalModal"
            title="Create Proposal"
            @ok="createProposal"
            @cancel="resetProposalData"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Create Proposal</h5>
                  <button
                    type="button"
                    class="close"
                    @click="showProposalModal = false"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="companyName">Company Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="companyName"
                      v-model="proposalData.companyName"
                    />
                  </div>
                  <div class="form-group">
                    <label for="coffeeSales">Coffee Sales</label>
                    <input
                      type="text"
                      class="form-control"
                      id="coffeeSales"
                      v-model="proposalData.coffeeSales"
                    />
                  </div>
                  <div class="form-group">
                    <label for="endCustomer">End Customer</label>
                    <input
                      type="text"
                      class="form-control"
                      id="endCustomer"
                      v-model="proposalData.endCustomer"
                    />
                  </div>
                  <div class="form-group">
                    <label for="effectiveDate">Effective Date</label>
                    <input
                      type="date"
                      class="form-control"
                      id="effectiveDate"
                      v-model="proposalData.effectiveDate"
                    />
                  </div>
                  <div class="form-group">
                    <label for="emailAddress">Your Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="emailAddress"
                      v-model="emailAddress"
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="createProposal"
                  >
                    Create Proposal
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showProposalModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import itemsData from "./O365ProjectData.json";

export default {
  name: "O365Project",
  data() {
    return {
      items: itemsData,
      totalHours: 0,
      totalDays: 0,
      totalCost: 0,
      emailAddress: "",
      proposalData: {
        companyName: "",
        coffeeSales: "",
        endCustomer: "",
        effectiveDate: "",
      },
      showProposalModal: false,
    };
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.updateCosts();
      },
    },
  },
  methods: {
    resetProposalData() {
      this.proposalData.companyName = "";
      this.proposalData.coffeeSales = "";
      this.proposalData.endCustomer = "";
      this.proposalData.effectiveDate = "";
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    updateCosts() {
      const totalTimeInMins = this.items.reduce((total, item) => {
        item.totalTime = item.timeToDo * item.quantity;
        return total + item.totalTime;
      }, 0);
      this.totalHours = (totalTimeInMins / 60).toFixed(2);
      this.totalDays = parseFloat(this.totalHours / 8).toFixed(2);
      this.totalCost = parseFloat(this.totalHours * 70).toFixed(2);
    },
    getPostData() {
      const data = {
        FileUrl: "https://files.tbuk.org/index.php/s/tRsJ4FYJ3j92QXL/download",
        ReplaceInformation: [
          { Find: "|COMPANYNAME|", Replace: this.proposalData.companyName },
          { Find: "|COFFEESALES|", Replace: this.proposalData.coffeeSales },
          { Find: "|ENDCUSTOMER|", Replace: this.proposalData.endCustomer },
          {
            Find: "|EFFECTIVEDATE|",
            Replace: this.formatDate(this.proposalData.effectiveDate),
          },
        ],
        EmailAddress: this.emailAddress,
      };

      // Create the Replace string containing non-zero quantity items
      const replaceString = this.items
        .filter((item) => item.quantity > 0)
        .map((item) => `(${item.docuheading})${item.docutext}`)
        .join("|");

      // Use the created Replace string for |TESTBIT|
      data.ReplaceInformation.push({
        Find: "|TESTBIT|",
        Replace: replaceString,
      });

      return data;
    },
    async createProposal() {
      const data = this.getPostData();

      try {
        const response = await fetch(
          "https://documaker.coffeecup.solutions/api/document/process",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          alert("Proposal created successfully!");
        } else {
          alert("Error creating proposal. Please try again.");
        }
      } catch (error) {
        alert("Error creating proposal. Please try again.");
      }

      this.showProposalModal = false;
    },
  },
  mounted() {
    this.updateCosts();
  },
};
</script>
