<!-- src/App.vue -->
<template>
  <div id="wrapper">
    <!-- Sidebar -->
    <div id="sidebar-wrapper">
      <ul class="sidebar-nav">
        <li class="sidebar-brand">
          <a href="#">Toms Super Tool</a>
        </li>
        <li>
          <router-link
            :to="{ name: 'AssuranceCalculator' }"
            active-class="active"
            exact-active-class="active"
          >
            Assurance Calculator
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'VoipCalculator' }"
            active-class="active"
            exact-active-class="active"
          >
            VOIP Calculator
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'O365Project' }"
            active-class="active"
            exact-active-class="active"
          >
            O365 Project
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'TemplatesPage' }"
            active-class="active"
            exact-active-class="active"
          >
            Templates
          </router-link>
        </li>
        <li v-if="!isAuthenticated">
          <a href="#" @click.prevent="signIn">Sign In with Office 365</a>
        </li>
        <li v-if="isAuthenticated">
          <a href="#" @click.prevent="signOut">Sign Out</a>
        </li>
        <!-- Add more menu items here -->
      </ul>
    </div>
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div id="page-content-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <!-- /#page-content-wrapper -->
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    signIn() {
      this.$msal.signIn().catch((error) => {
        console.error("Error signing in", error);
      });
    },
    signOut() {
      this.$msal.signOut();
    },
  },
  computed: {
    isAuthenticated() {
      return this.$msal.isAuthenticated();
    },
    user() {
      return this.$msal.getUser();
    },
  },
};
</script>

<style>
/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

body {
  overflow-x: hidden;
}

/* Toggle Styles */

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #272b44;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.sidebar-nav li.active a {
  background: #007bff;
  color: #fff;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}
</style>
