<template>
  <div>
    <h1>Templates Page</h1>
    <!-- Your template content goes here -->asdasdasd
  </div>
</template>

<script>
export default {
  name: 'TemplatesPage'
  // Your script here
};
</script>

<style>
/* Your style here */
</style>
