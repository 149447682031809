<!-- src/components/AssuranceCalculator.vue -->
<template>
  <div>
    <h1>Assurance Pricing Calculator</h1>
    <div class="row">
      <!-- Table takes up 2/3 of the screen space -->
      <div class="col-md-8">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Cost Today</th>
                <th>Total Cost</th>
                <th>Our Cost</th>
                <th>Our Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ item.name }}</td>
                <td>
                  <input v-if="item.editable" class="form-control" type="number" v-model.number="item.quantity" min="0"
                    @input="updateCosts" />
                  <span v-else>{{ item.quantity }}</span>
                </td>
                <td>{{ item.cost | toFixedTwo }}</td>
                <td>{{ item.total | toFixedTwo }}</td>
                <td>{{ item.ourCost | toFixedTwo }}</td>
                <td>{{ item.ourTotal | toFixedTwo }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Text bits are on the right side -->
      <div class="col-md-4">
        <p><b>Customer Cost:</b> £{{ customerCost }}</p>
        <p><b>Our Cost:</b> £{{ ourCost }}</p>
        <p><b>Profit New:</b> £{{ profitNew }}</p>
        <p><b>Software Costs:</b> £{{ softwareCosts }}</p>
        <!-- Add this below the existing text bits -->
        <div>
          <button class="btn btn-primary" v-b-modal="'proposalModal'">Create Proposal</button>
          <b-modal id="proposalModal" title="Create Proposal" @ok="createProposal" @cancel="resetProposalData">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Create Proposal</h5>
                  <button type="button" class="close" @click="showProposalModal = false" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="companyName">Company Name</label>
                    <input type="text" class="form-control" id="companyName" v-model="proposalData.companyName">
                  </div>
                  <div class="form-group">
                    <label for="coffeeSales">Coffee Sales</label>
                    <input type="text" class="form-control" id="coffeeSales" v-model="proposalData.coffeeSales">
                  </div>
                  <div class="form-group">
                    <label for="endCustomer">End Customer</label>
                    <input type="text" class="form-control" id="endCustomer" v-model="proposalData.endCustomer">
                  </div>
                  <div class="form-group">
                    <label for="effectiveDate">Effective Date</label>
                    <input type="date" class="form-control" id="effectiveDate" v-model="proposalData.effectiveDate">
                  </div>
                  <div class="form-group">
                    <label for="emailAddress">Your Email Address</label>
                    <input type="email" class="form-control" id="emailAddress" v-model="emailAddress" />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="createProposal">Create Proposal</button>
                  <button type="button" class="btn btn-secondary" @click="showProposalModal = false">Cancel</button>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import itemsData from "./assuranceData.json";

export default {
  name: "AssuranceCalculator",
  data() {
    return {
      items: itemsData,
      emailAddress: "",
      proposalData: {
        companyName: "",
        coffeeSales: "",
        endCustomer: "",
        effectiveDate: "",
      },
    };
  },
  computed: {
    customerCost() {
      return parseFloat(this.items.reduce((total, item) => total + item.total, 0)).toFixed(2);
    },
    ourCost() {
      return parseFloat(this.items.reduce((total, item) => total + item.ourTotal, 0)).toFixed(2);
    },
    profitNew() {
      return (this.customerCost - this.ourCost).toFixed(2);
    },
    softwareCosts() {
      const softwareItems = ['office', 'rmm', 'keeper', 'ironscales', 'log_server'];
      return this.items.filter(item => softwareItems.includes(item.id)).reduce((total, item) => total + item.ourTotal, 0).toFixed(2);
    },
  },
  methods: {
    resetProposalData() {
      this.proposalData.companyName = "";
      this.proposalData.coffeeSales = "";
      this.proposalData.endCustomer = "";
      this.proposalData.effectiveDate = "";
    },
    formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
    },
    getItemById(id) {
      return this.items.find(item => item.id === id);
    },
    updateCosts() {
      // Calculate the quantities of Users, Mobile Users, and Ancillary Users
      const usersQuantity = this.getItemById('users').quantity;
      const mobileUsersQuantity = this.getItemById('mobile').quantity;
      const ancillaryUsersQuantity = this.getItemById('ancillary').quantity;

      // Perform calculations for Office 365 Backup, RMM, Keeper (Password Manager), Ironscales Core (Spam/Training), Log server
      const officeBackup = this.getItemById('office');
      officeBackup.quantity = usersQuantity + mobileUsersQuantity + ancillaryUsersQuantity;

      const rmm = this.getItemById('rmm');
      rmm.quantity = usersQuantity;

      const keeper = this.getItemById('keeper');
      keeper.quantity = usersQuantity + mobileUsersQuantity;

      const ironscales = this.getItemById('ironscales');
      ironscales.quantity = usersQuantity + mobileUsersQuantity + ancillaryUsersQuantity;

      const logServer = this.getItemById('log_server');
      logServer.quantity = usersQuantity;

      this.items.forEach((item, index) => {
        item.total = item.quantity * item.cost;
        item.ourCost = item.ourCostDefault;
        item.ourTotal = item.quantity * item.ourCost;

        Vue.set(this.items, index, item);
      });
    },
    async createProposal() {
      // Store the calculations in variables
      const calcSiteCost = this.getItemById("sites").quantity * 54;
      const calcAssuranceUsers = this.getItemById("users").quantity * 49;
      const calcMobileUsers = this.getItemById("mobile").quantity * 25;
      const calcAncillaryUsers = this.getItemById("ancillary").quantity * 5;
      const calcTotalMSPCost = calcSiteCost + calcAssuranceUsers + calcMobileUsers + calcAncillaryUsers;
      const data = {
        FileUrl: "https://s3.eu-central-1.wasabisys.com/cc-static-assets/web/Assurance-BuilderQuote.docx",
        ReplaceInformation: [
          { Find: "|COMPANYNAME|", Replace: this.proposalData.companyName },
          { Find: "|COFFEESALES|", Replace: this.proposalData.coffeeSales },
          { Find: "|ENDCUSTOMER|", Replace: this.proposalData.endCustomer },
          { Find: "|LOCATIONCOUNT|", Replace: this.getItemById("sites").quantity.toString() },
          { Find: "|TOTALFULLUSERS|", Replace: this.getItemById("users").quantity.toString() },
          { Find: "|TOTALMOBILEUSERS|", Replace: this.getItemById("mobile").quantity.toString() },
          { Find: "|TOTALANCILLARYUSERS|", Replace: this.getItemById("ancillary").quantity.toString() },
          { Find: "|EFFECTIVEDATE|", Replace: this.formatDate(this.proposalData.effectiveDate) },
          { Find: "|CALCSITEBASECHARGE|", Replace: `£${(this.getItemById("sites").quantity * 100).toFixed(2)}` },
          { Find: "|CALCASSETCHARGE|", Replace: `£${(this.getItemById("users").quantity * 40).toFixed(2)}` },
          { Find: "|CALCTOTALONBOARDING|", Replace: `£${((this.getItemById("sites").quantity * 100) + (this.getItemById("users").quantity * 40)).toFixed(2)}` },
          { Find: "|CALCSITECOST|", Replace: `£${(this.getItemById("sites").quantity * 54).toFixed(2)}` },
          { Find: "|CALCSITECOST|", Replace: `£${calcSiteCost.toFixed(2)}` },
          { Find: "|CALCASSURANCEUSERS|", Replace: `£${calcAssuranceUsers.toFixed(2)}` },
          { Find: "|CALCMOBILEUSERS|", Replace: `£${calcMobileUsers.toFixed(2)}` },
          { Find: "|CALCANCILLARY|", Replace: `£${calcAncillaryUsers.toFixed(2)}` },
          { Find: "|CALCTOTALMSPCOST|", Replace: `£${calcTotalMSPCost.toFixed(2)}` },],
        EmailAddress: this.emailAddress,
      };

      try {
        const response = await fetch("https://documaker.coffeecup.solutions/api/document/process", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          alert("Proposal created successfully!");
        } else {
          alert("Error creating proposal. Please try again.");
        }
      } catch (error) {
        alert("Error creating proposal. Please try again.");
      }

      this.showProposalModal = false;
    },
  },
  created() {
    this.updateCosts();
  },
};
</script>