import Vue from 'vue';
import Router from 'vue-router';
import AssuranceCalculator from './components/AssuranceCalculator.vue';
import VoipCalculator from './components/VoipCalculator.vue';
import O365Project from './components/O365Project.vue';
import TemplatesPage from './components/TemplatesPage.vue';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'AssuranceCalculator',
    component: AssuranceCalculator,
    beforeEnter: requireAuth
  },
  {
    path: '/voip',
    name: 'VoipCalculator',
    component: VoipCalculator,
    beforeEnter: requireAuth
  },
  {
    path: '/o365project',
    name: 'O365Project',
    component: O365Project,
    beforeEnter: requireAuth
  },
  // New Templates route
  {
    path: '/templatespage',
    name: 'Templates',
    component: TemplatesPage,
    beforeEnter: requireAuth
  },
  // Add additional new routes here
];
function requireAuth(to, from, next) {
  if (Vue.prototype.$msal.isAuthenticated()) {
    // User is authenticated, continue
    next();
  } else {
    // User is not authenticated, prompt for sign in
    Vue.prototype.$msal.signIn();
  }
}
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});