import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue } from "bootstrap-vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Import vue-msal
import VueMsal from "vue-msal";

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

Vue.filter("toFixedTwo", function(value) {
  return parseFloat(value).toFixed(2);
});
var customRedirectURI = 'https://pricing.coffeecup.solutions/';
if(process.env.NODE_ENV === 'development')
{
  customRedirectURI = 'http://localhost:8080'
}

// Configure vue-msal
Vue.use(VueMsal, {
  auth: {
    clientId: "a54fdf31-ffd5-4194-ba3f-8283383921cc",
    tenantId: "be541257-aca9-4ffd-af3d-aa2373006513",
    authority: "https://login.microsoftonline.com/be541257-aca9-4ffd-af3d-aa2373006513",
    redirectUri: customRedirectURI,
    scopes: ["user.read", "openid", "profile", "email"]
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");