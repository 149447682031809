
<template>
  <div>
    <h1>3cx VOIP Pricing Calculator</h1>
   
    <b-row>
      <b-col>
        <b-form-group label="Number of Users">
          <b-form-input
            type="number"
            v-model="getItemById('number_of_users').quantity"
            @input="updateCosts"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Sell Price">
          <b-form-input
            type="number"
            v-model="getItemById('sell_price').cost"
            @input="updateCosts"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Mins Included per Person">
          <b-form-input
            type="number"
            v-model="getItemById('mins_included').quantity"
            @input="updateCosts"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Direct Dial">
          <b-form-input
            type="number"
            v-model="getItemById('direct_dial').quantity"
            @input="updateCosts"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-container> </b-container>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
                <th>Our Cost</th>
                <th>Our Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in calculatedItems" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.ourCost }}</td>
                <td>{{ item.ourTotal }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-4">
        <p><b>Our Cost:</b> £{{ ourCostTotal }}</p>
        <p><b>Sell Price:</b> £{{ sellPriceTotal }}</p>
        <p><b>Profit:</b> £{{ profitTotal }}</p>
        <!-- Add the Create Proposal button here -->
        <div>
          <button class="btn btn-primary" v-b-modal="'proposalModal'">Create Proposal</button>
          <b-modal id="proposalModal" title="Create Proposal" @ok="createProposal" @cancel="resetProposalData">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Create Proposal</h5>
                  <button type="button" class="close" @click="showProposalModal = false" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="companyName">Company Name</label>
                    <input type="text" class="form-control" id="companyName" v-model="proposalData.companyName">
                  </div>
                  <div class="form-group">
                    <label for="coffeeSales">Coffee Sales</label>
                    <input type="text" class="form-control" id="coffeeSales" v-model="proposalData.coffeeSales">
                  </div>
                  <div class="form-group">
                    <label for="endCustomer">End Customer</label>
                    <input type="text" class="form-control" id="endCustomer" v-model="proposalData.endCustomer">
                  </div>
                  <div class="form-group">
                    <label for="effectiveDate">Effective Date</label>
                    <input type="date" class="form-control" id="effectiveDate" v-model="proposalData.effectiveDate">
                  </div>
                  <div class="form-group">
                    <label for="setupCost">Setup Cost</label>
                    <input type="text" class="form-control" id="setupCost" v-model="proposalData.setupCost">
                  </div>
                  <div class="form-group">
                    <label for="emailAddress">Your Email Address</label>
                    <input type="email" class="form-control" id="emailAddress" v-model="emailAddress" />
                  </div>
                  </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="createProposal">Create Proposal</button>
                  <button type="button" class="btn btn-secondary" @click="showProposalModal = false">Cancel</button>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemsData from "./voipData.json";

export default {
  name: "VoipCalculator",
  data() {
    return {
      items: itemsData,
      emailAddress: "",
      proposalData: {
        companyName: "",
        coffeeSales: "",
        endCustomer: "",
        effectiveDate: "",
        setupCost: "",
        
      },
    };
  },
  computed: {
    calculatedItems() {
      const items = [];
      const NOU = this.getItemById("number_of_users").quantity;
      const mins_included = this.getItemById("mins_included").quantity;

      // HOSTING item
      const hostingItem = { ...this.getItemById("hosting") };
      hostingItem.ourCost = parseFloat(hostingItem.cost).toFixed(2);
      // hostingItem.ourTotal = parseFloat(
      //   hostingItem.ourCost * hostingItem.quantity
      // ).toFixed(2);
      items.push(hostingItem);

      // LICENSE item
      const licenseItem = { ...this.getItemById("license") };
      licenseItem.quantity = NOU;
      licenseItem.ourCost = parseFloat(licenseItem.cost).toFixed(2);
      licenseItem.ourTotal = parseFloat(
        licenseItem.ourCost * licenseItem.quantity
      ).toFixed(2);
      items.push(licenseItem);

      // MINS RATE item
      const minsRateItem = {name: "Mins Included",
        quantity: mins_included * NOU,
      };
      minsRateItem.ourCost = parseFloat(
        this.getItemById("mins_rate").cost
      ).toFixed(4);
      minsRateItem.ourTotal = parseFloat(
        minsRateItem.ourCost * minsRateItem.quantity
      ).toFixed(2);
      items.push(minsRateItem);

      // DIRECT DIAL item
      const directDialItem = { ...this.getItemById("direct_dial") };
      directDialItem.ourCost = parseFloat(directDialItem.cost).toFixed(2);
      directDialItem.ourTotal = parseFloat(
        directDialItem.ourCost * directDialItem.quantity
      ).toFixed(2);
      items.push(directDialItem);

      return items;
    },
    ourCostTotal() {
      return this.calculatedItems
        .reduce((total, item) => total + parseFloat(item.ourTotal), 0)
        .toFixed(2);
    },
    sellPriceTotal() {
      const NOU = Number(this.getItemById("number_of_users").quantity);
      const sellPrice = Number(this.getItemById("sell_price").cost);
      const directDialFactor = Number(this.getItemById("direct_dial").quantity);
      const adjustedDirectDialFactor = Math.max(0, directDialFactor - 1);
      return ((NOU * sellPrice) + adjustedDirectDialFactor).toFixed(2);
    },
    profitTotal() {
      return (
        parseFloat(this.sellPriceTotal) - parseFloat(this.ourCostTotal)
      ).toFixed(2);
    },
  },
  methods: {
    getItemById(id) {
      return this.items.find((item) => item.id === id);
    },
    formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
    },
    resetProposalData() {
      this.proposalData.companyName = "";
      this.proposalData.coffeeSales = "";
      this.proposalData.endCustomer = "";
      this.proposalData.effectiveDate = "";
      this.proposalData.setupCost = "";
    },
    updateCosts() {
      // Add the logic here for updating the costs, or simply leave it empty if no specific operation is required
    },
    async createProposal() {
      const NOU = this.getItemById("number_of_users").quantity;
      const sellPrice = this.getItemById("sell_price").cost;
      const directDialQuantity = this.getItemById("direct_dial").quantity;
      const totalMins = this.getItemById("mins_included").quantity * NOU;
      const directDialCost = this.getItemById("direct_dial").cost;
      const calcDirectDial = (directDialQuantity - 1) * directDialCost;
      const data = {
        FileUrl: "https://s3.eu-central-1.wasabisys.com/cc-static-assets/temp/VOIP-BuilderQuote.docx",
        ReplaceInformation: [
          { Find: "|VOIPUSERS|", Replace: NOU.toString() },
          { Find: "|SETUPCOST|", Replace: `£${parseFloat(this.proposalData.setupCost).toFixed(2)}` },
          { Find: "|TOTALVOIPCOST|", Replace: `£${parseFloat(this.sellPriceTotal).toFixed(2)}` },
          { Find: "|VOIPCOST|", Replace: `£${parseFloat(sellPrice).toFixed(2)}` },
          { Find: "|COMPANYNAME|", Replace: this.proposalData.companyName },
          { Find: "|COFFEESALES|", Replace: this.proposalData.coffeeSales },
          { Find: "|ENDCUSTOMER|", Replace: this.proposalData.endCustomer },
          { Find: "|EFFECTIVEDATE|", Replace: this.formatDate(this.proposalData.effectiveDate) },
          { Find: "|PHONENUMBERS|", Replace: directDialQuantity.toString() },
          { Find: "|TOTALMINUTES|", Replace: totalMins.toString() },
          { Find: "|DIRECTDIALNUMBERS|", Replace: directDialQuantity.toString() },
          { Find: "|CALCDIRECTDIAL|", Replace: `£${parseFloat(calcDirectDial).toFixed(2)}` }, 
        ],
        EmailAddress: this.emailAddress,
      };

      try {
        const response = await fetch("https://documaker.coffeecup.solutions/api/document/process", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          alert("Proposal created successfully!");
        } else {
          alert("Error creating proposal. Please try again.");
        }
      } catch (error) {
        alert("Error creating proposal. Please try again.");
      }

      this.$bvModal.hide("proposalModal");
    },
  },
};
</script>

<style scoped>
.row {
  padding-bottom: 1rem;
}

table {
  margin-bottom: 1rem;
}
</style>
       